<template>
	<div class="box">
		<div class="headerTop">

		</div>
		<div class="conHote">
			<!-- 房间信息 -->
			<div class="dataTime">
				<div class="time">
					<p>
						<span>8月03日</span>
						<span style="color: #666666;font-size: 0.13rem;">今天</span>
					</p>
					
					<p>
						<span>8月05日</span>
						<span style="color: #666666;font-size: 0.13rem;">周四</span>
					</p>
				</div>
				<p style="margin: 10px 0;font-weight: bold;">经济特价大床房</p>
				<p style="color: #666666;font-size: 0.18px;">无早餐 15-30m² 大床 2人入住 有窗</p>
				<div class="readbook">
					<van-icon name="info" />
					<p style="color: #FC6228;">订房必读</p>
				</div>
			</div>
			<!-- 入住信息 -->
			<div class="checkIn">
				<div class="infoRoom">
					<p>入住信息</p>
					<p style="font-size: 0.14rem;color: #999999;margin-left: 15px;">姓名需要与证件一致，每间填一位住客姓名</p>
				</div>
				<div class="roomNum">
					<p>房间数</p>
					<p style="font-size: 0.2rem;margin-left: 15px;">1间</p>
				</div>
				<div class="roomPeo">
					<p>入住人</p>
					<p style="font-size: 0.2rem;margin-left: 15px;">哈哈哈</p>
				</div>
				<div class="roomTel">
					<p>联系手机号</p>
					<p style="font-size: 0.2rem;margin-left: 15px;">1888888888</p>
				</div>
				<div class="roomData">
					<p>预计到店</p>
					<p style="font-size: 0.2rem;margin-left: 15px;">14:00</p>
					<p style="font-size: 0.14rem;color: #999999;margin-left: 15px;">房间将整晚保留</p>
				</div>
			</div>
			<!-- 费用明细 -->
			<div class="breakdown">
				<h4>费用明细</h4>
				<div class="breakdown1">
					<p>在线支付</p>
					<p>
						<span>1间3晚 共</span>
						<span style="color: #FC6228;">2367元</span>
					</p>
				</div>
				<div class="roomPrice">
					<p style="font-weight: bold;">房费</p>
					<p style="display: flex;justify-content: space-between;font-size: 0.18px;margin: 5px 0;">
						<span style="color: #666666;">2021-08-03 无早餐</span>
						<span>1×￥789</span>
					</p>
					<p style="display: flex;justify-content: space-between;font-size: 0.18px;margin: 5px 0;">
						<span style="color: #666666;">2021-08-03 无早餐</span>
						<span>1×￥789</span>
					</p>
					<p style="display: flex;justify-content: space-between;font-size: 0.18px;margin: 5px 0;">
						<span style="color: #666666;">2021-08-03 无早餐</span>
						<span>1×￥789</span>
					</p>
				</div>
			</div>
		</div>
		<div class="footerSub">
			<p>合计: <span>￥2367元</span></p>
			<button>提交订单</button>
		</div>
	</div>
</template>

<script>
</script>

<style lang="less" scoped>
	p {
		margin: 0;
		padding: 0;
	}

	.box {
		background-color: #f6f6f6;

		.headerTop {
			width: 100%;
			height: 2.5rem;
			background-color: #FC6228;
		}

		.conHote {
			width: 100%;
			// height: 3rem;
			// border: solid 1px #007AFF;
			margin-top: -1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.dataTime {
				width: 88%;
				// height: 2.3rem;
				background: #FFFFFF;
				border-radius: 20px;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;
				padding: 15px;
				.time{
					display: flex;
				}
				.readbook{
					margin-top: 0.2rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}

			.checkIn {
				width: 88%;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 20px;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;
				padding: 15px;

				.infoRoom,
				.roomNum,
				.roomPeo,
				.roomTel,
				.roomData {
					display: flex;
					margin-bottom: 10px;
				}
			}

			.breakdown {
				width: 88%;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 20px;
				margin-bottom: 1.2rem;
				font-size: 0.2rem;
				padding: 15px;

				h4 {
					margin: 0;
					padding: 0;
					font-size: 0.25rem;
				}

				.breakdown1 {
					display: flex;
					justify-content: space-between;
					font-size: 0.22rem;
					margin: 5px 0;
				}

				.roomPrice {
					margin-top: 0.4rem;
				}
			}
		}

		.footerSub {
			position: fixed;
			bottom: 0;
			width: 100%;
			height: 0.8rem;
			font-size: 0.2rem;
			background: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: space-between;

			p {
				margin-left: 0.2rem;

				span {
					color: #FC6228;
				}
			}

			button {
				margin-right: 0.2rem;
				width: 100px;
				height: 35px;
				background: #FC6228;
				border-radius: 10px;
				border: none;
				color: #FFFFFF;
			}
		}
	}
</style>
